<template>
  <v-data-table
      :headers="headers"
      :items="varsity_infos"
      sort-by="calories"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Varsity Info</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.title"
                        label="Enter title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
<!--                    <quill-editor-->
<!--                        v-model="editedItem.body"-->
<!--                        :options="{placeholder: 'Enter description'}"-->
<!--                    />-->
                    <tiptap-vuetify
                        v-model="editedItem.body"
                        :extensions="extensions"
                        placeholder="Enter description"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        v-model="photo"
                        label="Choose a photo"
                        clearable
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.body="{ item }">
      <div v-html="item.body"></div>
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img max-width="100" :src="item.photo"></v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          color="blue"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
          color="red"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script
    src="https://code.jquery.com/jquery-3.5.1.min.js"
    integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    crossorigin="anonymous"></script>
<script>
import axios from "axios";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  components: {
    quillEditor,
    TiptapVuetify,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    photo: null,
    headers: [
      {
        text: 'Photo',
        value: 'photo'
      },
      {
        text: 'Title',
        align: 'start',
        value: 'title',
      },
      {
        text: 'Details',
        value: 'body',
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    varsity_infos: [],
    editedIndex: -1,
    editedItem: {
      title: '',
      body: '',
    },
    defaultItem: {
      title: '',
      body: '',
    },
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      } else {
        formData.append('varsity_id', this.$route.params.varsityId)
      }
      formData.append('title', this.editedItem.title)
      formData.append('body', this.editedItem.body)
      if (this.photo) {
        formData.append('photo', this.photo)
      }
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const url = 'admin/varsity-info?id=' + this.$route.params.varsityId
      axios.get(url).then((response) => {
        this.varsity_infos = response.data.varsity_infos
      }).catch(() => {

      })
    },

    editItem(item) {
      this.editedIndex = this.varsity_infos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.varsity_infos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/varsity-info/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.varsity_infos.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/varsity-info/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.varsity_infos[editedIndex], response.data)
        })
      } else {
        const url = 'admin/varsity-info'
        axios.post(url, this.form).then((response) => {
          this.varsity_infos.push(response.data)
        })
      }
      this.close()
    },
  },
}
</script>
